const Resources = () => {
    return (
      <>
        <div className="px-3">
          resources......
        </div>
      </>
    )
  };
  
  export default Resources;